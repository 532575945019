<template>
  <div class="user">
    <div class="header-wrapper">
      <div class="header">
        <div class="user-header">
          <img :src="user.HeadImg">
        </div>
      </div>
      <div class="name"
           :v-text="user.NickName"
           style="font-size: 19px; font-weight: bold;">
      </div>
    </div>
    <div class="link-list">
      <van-cell title="房价查询记录"
                icon="label"
                is-link
                @click="gotoList" />
    </div>
    <!--        <van-button type="info" @click="clear">退出</van-button>-->

  </div>
</template>

<script>
import { getUserInfo } from '@/api/user'

export default {
  name: 'user',
  data () {
    return {
      user: {
        Id: '',
        BankId: '',
        HeadImg: '',
        NickName: '',
        CreateTime: '',
        OpenId: ''
      }
    };
  },
  created () {
    this.$store.state.tabBar = this.$route.name;
    this.getUserInfos();
  },
  methods: {

    getUserInfos () {
      getUserInfo().then((res) => {
        this.user = res.data;
      }).catch(e => {
        console.log(e);
      })
    },
    gotoList () {
      this.$router.push('/querylist')
    },
    clear () {
      localStorage.removeItem('auth')
    }
  }
}
</script>
<style lang="less" scoped>
.header-wrapper {
  width: 100%;
  overflow: hidden;
  padding-bottom: 55px;
  position: relative;

  .header {
    height: 4rem;
    background: linear-gradient(to left, #09f77b, #07c160);
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    position: relative;
    left: -10%;
    width: 120%;
    height: 180px;
    position: relative;

    .user-header {
      position: absolute;
      bottom: -30px;
      left: 50%;
      width: 80px;
      height: 80px;
      transform: translateX(-50%);
      border-radius: 50%;
      overflow: hidden;
      background-color: #fff;
      border: 4px solid #07c160;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .name {
    font-size: 14px;
    color: #323233;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
  }
}

.link-list {
  width: 95%;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #a9a9a9;
  overflow: hidden;
}
</style>
